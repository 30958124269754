import { useState, useEffect } from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

export function Completed(props) {

    const [show, setShow] = useState(false);

    const handleClose = () => {
      setShow(false);
      props.setStarted(false);
      props.setCompleted(false);
    }
    const handleShow = () => setShow(true);
    //

    //props.completed ? useEffect(()=>{ setShow(props.completed) }, [])

    useEffect(() => {
      //console.log("component mounted");
      handleShow();

      //setShow(props.completed);
      //
      return () => {
        //console.log("component unmounted");
      }
    }, [])
  
    return (
      <>
        {/*<Button variant="primary" onClick={handleShow}>
          Launch demo modal
    </Button>*/}
  
        {/*<Modal show={show} onHide={handleClose}>*/}
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Congratulations</Modal.Title>
          </Modal.Header>
          <Modal.Body>You have completed the slider puzzle. Your seven digit code is <strong>8r1tw38</strong>.</Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={handleClose}>
              Play Again
            </Button>
            {/*<Button variant="primary" onClick={handleClose}>
              Save Changes
            </Button>*/}
          </Modal.Footer>
        </Modal>
      </>
    );
}

//export default StaticExample;