import './App.css';
import { useState, createContext } from "react";
import { Board } from './Board';

export const AppContext = createContext();

function App() {
  const [ blankSquare, setBlankSquare ] = useState({top:300, left:300});
  return (
    <div className="App">
      <AppContext.Provider value={{blankSquare, setBlankSquare}}>
        <Board />
      </AppContext.Provider>
    </div>
  );
}

export default App;
