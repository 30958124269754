import { useContext } from "react";
import { AppContext } from "../App";

export function Shuffle(props){
    const { setBlankSquare } = useContext(AppContext);
    const shuffleSquares = () => {
        props.setStarted(true);
        const shuffle = [
            {top:0, left:0},
            {top:0, left:100},
            {top:0, left:200},
            {top:0, left:300},
            {top:100, left:0},
            {top:100, left:100},
            {top:100, left:200},
            {top:100, left:300},
            {top:200, left:0},
            {top:200, left:100},
            {top:200, left:200},
            {top:200, left:300},
            {top:300, left:0},
            {top:300, left:100},
            {top:300, left:200}
        ];
        //
        function randomize(a, b) {
            return Math.random() - 0.5;
        }
        shuffle.sort(randomize);
        //console.log(shuffle);
        const newSetSquares = props.squares.map((square, index) => {
            return{
                ...square,
                top: shuffle[index].top,
                left: shuffle[index].left
            };
            //return square;
        });
        props.setSquares(newSetSquares);
        //
        setBlankSquare({top:300, left:300});
        {/*props.setSquares([
            {id:1, top:100, left:100, background:'0 0'},
            {id:2, top:200, left:0, background:'-100px 0'},
            {id:3, top:0, left:0, background:'-200px 0'},
            {id:4, top:200, left:100, background:'0 -100px'},
            {id:5, top:0, left:100, background:'-100px -100px'},
            {id:6, top:100, left:200, background:'-200px -100px'},
            {id:7, top:100, left:0, background:'0 -200px'},
            {id:8, top:0, left:200, background:'-100px -200px'}
        ]);*/}
    }
    return(
        <div>
            <button className="btn btn-primary" onClick={shuffleSquares}>{props.started ? 'Shuffle Again' : 'Start Puzzle'}</button>
        </div>
    );
}