import { useState, useContext } from "react";
import { AppContext } from "./App";
import { Square } from './components/Square';
import { Shuffle } from './components/Shuffle';
import { Completed } from './components/Completed';

export function Board(){
    const [ started, setStarted] = useState(false);
    const [ completed, setCompleted] = useState(false);
    const { blankSquare } = useContext(AppContext);
    const initialSquaresState = [
        {id:1, top:0, left:0, background:'0 0'},
        {id:2, top:0, left:100, background:'-100px 0'},
        {id:3, top:0, left:200, background:'-200px 0'},
        {id:4, top:0, left:300, background:'-300px 0'},
        {id:5, top:100, left:0, background:'0 -100px'},
        {id:6, top:100, left:100, background:'-100px -100px'},
        {id:7, top:100, left:200, background:'-200px -100px'},
        {id:8, top:100, left:300, background:'-300px -100px'},
        {id:9, top:200, left:0, background:'0 -200px'},
        {id:10, top:200, left:100, background:'-100px -200px'},
        {id:11, top:200, left:200, background:'-200px -200px'},
        {id:12, top:200, left:300, background:'-300px -200px'},
        {id:13, top:300, left:0, background:'0 -300px'},
        {id:14, top:300, left:100, background:'-100px -300px'},
        {id:15, top:300, left:200, background:'-200px -300px'}
    ];
    //
    const [ squares, setSquares ] = useState(initialSquaresState);
    return(
        <div className="container">
            <p>Before you start the puzzle, take note of the completed image.</p>
            <p>Click on the 'Start Puzzle' button to randomly shuffle the pieces. You can only move one piece at a time into the vacant square. That piece must be adjacent to the vacant square.</p>
            <p>You can shuffle the pieces as many times as you like by clicking the 'Shuffle Again' button.</p>
            <p>When you have completed the puzzle, you will receive a 7 digit code. Post this code to the Teams group to see if you are quicker than the other participants.</p>
            <p>Good luck!</p>
            <div className="board">
                {squares.map((square, key) => <Square square={square} initialSquaresState={initialSquaresState} squares={squares} setSquares={setSquares} started={started} setStarted={setStarted} completed={completed} setCompleted={setCompleted} key={key} />)}
            </div>
            {/*<h1>Blank square is:<br />Left: {blankSquare.left}<br />Top: {blankSquare.top}</h1>*/}
            <Shuffle started={started} setStarted={setStarted} squares={squares} setSquares={setSquares} />
            {/*<h2>{JSON.stringify(initialSquaresState) === JSON.stringify(squares) ? <span>Completed</span> : <span>In Play</span>}</h2>*/}
            {completed && <Completed started={started} setStarted={setStarted} completed={completed} setCompleted={setCompleted} />}
        </div>
    );
}