import { useContext } from "react";
import { AppContext } from "../App";

export function Square(props){
    const { blankSquare, setBlankSquare } = useContext(AppContext);
    const changeSquarePosition = (id, event) => {
        if (event.target.offsetLeft === blankSquare.left){
            //console.log("same column");
            //isLegal = true;
            const garry = blankSquare.top - event.target.offsetTop;
            //alert(garry);
            if (garry === 100 || garry === -100){
                //setSquareTopPosition(event.target.offsetTop + garry);
                const newSetSquares = props.squares.map((square, index) => {
                    if (id === index+1){
                        return{
                            ...square,
                            top: event.target.offsetTop + garry,
                        };
                    }
                    else{
                        return square;
                    }
                });
                props.setSquares(newSetSquares);
                setBlankSquare({top:event.target.offsetTop, left:event.target.offsetLeft});
                //
                if (JSON.stringify(props.initialSquaresState) === JSON.stringify(newSetSquares) && props.started) {
                    props.setCompleted(true);
                }
            }
        }
        //
        if (event.target.offsetTop === blankSquare.top){
            //console.log("same row");
            //isLegal = true;
            const garry = blankSquare.left - event.target.offsetLeft;
            //alert(garry);
            if (garry === 100 || garry === -100){
                //setSquareLeftPosition(event.target.offsetLeft + garry);
                const newSetSquares = props.squares.map((square, index) => {
                    if (id === index+1){
                        return{
                            ...square,
                            left: event.target.offsetLeft + garry
                        };
                    }
                    else{
                        return square;
                    }
                });
                props.setSquares(newSetSquares);
                setBlankSquare({top:event.target.offsetTop, left:event.target.offsetLeft});
                //
                if (JSON.stringify(props.initialSquaresState) === JSON.stringify(newSetSquares) && props.started) {
                    props.setCompleted(true);
                }
            }
        }
    }
    //
    return(
        <button className="square" onClick={(event) => props.started && changeSquarePosition(props.square.id, event)} style={{position:"absolute", top:props.square.top, left:props.square.left, backgroundPosition:props.square.background}}></button>
    );
}